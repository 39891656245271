// Import the extendTheme and ThemeConfig types from Chakra UI
import { extendTheme, type ThemeConfig } from "@chakra-ui/react"

// Define a configuration object for the theme
const config: ThemeConfig = {
  initialColorMode: "dark", // Set the initial color mode to dark
  useSystemColorMode: false, // Disable the system color mode
}

// Extend the default Chakra UI theme with the custom configuration
const theme = extendTheme({ config })

// Export the custom theme as the default export of this module
export default theme