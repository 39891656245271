import * as React from "react"
import {
    ChakraProvider,
    Box,
    VStack,
    Grid,
    Heading,
    Highlight
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./components/ColorModeSwitcher"
import theme from "./theme"

export const App = () => (
    <ChakraProvider theme={theme}>
        <Box textAlign="center">
            <Grid minH="100vh" p={3}>
                <ColorModeSwitcher justifySelf="flex-end" />
                <VStack spacing={8}>
                    <Heading size="4xl" lineHeight="tall">
                        <Highlight
                            query="AI-Powered"
                            styles={{ bgGradient: "linear(to-r, red.400,pink.400)", bgClip: "text" }}
                        >
                            Building the AI-Powered journey maker.
                        </Highlight>
                    </Heading>
                </VStack>
            </Grid>
        </Box>
    </ChakraProvider>
)
